import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import $ from "jquery";
import Foundation from "foundation-sites";

import 'controllers'

import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

import "application.scss";

// Images
import "images/facebook.svg";
import "images/instagram.svg";
import "images/linkedin.svg";
import "images/logo-m.svg";
import "images/logo.svg";
import "images/menu.svg";
import "images/millref.png";
import "images/milcatdog.jpg";
import "images/close.svg";
import "images/arrow-right.svg";
import "images/arrow-right-red.svg";
import "images/chevron-right.svg";
import "images/chevron-right-green.svg";
import "images/telephone-alt.svg";
import "images/mvp-logo.jpg";
import "images/telephone.svg";
import "images/petsonwhite3.jpg";
import "images/whychooseus_grad.jpg";
import "images/theteam_grad.jpg";
import "images/pawprints_bg.png";
import "images/vet-with-cat.jpg";
import "images/guinea-pig.jpg";
import "images/turtles.jpg";
import "images/surgery.jpg";
import "images/horizontal-logo.jpg";
import "images/white-referrals.png";
import "images/klaser-white.jpeg";
import "images/pets-app-logo.png";
import "images/accredited-practice.JPG";
import "images/cat-friendly-logo.png";
import "images/rsavet-referral.jpg";
import "images/chicken-vet-logo.png";
import "images/phovia-logo.png";

Rails.start();
ActiveStorage.start();
Turbolinks.start();
Foundation.addToJquery($);

document.addEventListener("turbolinks:load", function () {
  $(document).foundation();

  $("[data-slick='banner-slider']").slick({
    dots: false,
    arrows: false,
    draggable: false,
    fade: true,
  });

  $("[data-slick='banner-slider-text']").slick({
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 6000,
    draggable: false,
    asNavFor: "[data-slick='banner-slider']",
  });

  $("[data-slick='show-banner-slider']").slick({
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    draggable: true,
    infinite: true,
  });

  $("[data-slick='pet-slider']").slick({
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 6000,
    draggable: true,
    infinite: true,
    fade: true,
  });

  $("[data-slick='testimonial-slider']").slick({
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    draggable: true,
    infinite: true,
  });

  $("#js-nav-toggle").on("click", function () {
    $(this).toggleClass("is-active");
    $("#js-nav-menu").toggleClass("is-active");
  });

  $("#COVID19Popup").foundation("open");

});
