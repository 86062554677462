import { Application } from "stimulus"
import { definitionsFromContext } from "@stimulus/webpack-helpers"
import { RemoteController } from 'stimulus-remote'
import NestedForm from "stimulus-rails-nested-form"


const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.register("nested-form", NestedForm)
application.register('remote', RemoteController)
